import '../../boot'
import { createApp } from 'vue/dist/vue.esm-bundler'
import { NButton, NResult } from 'naive-ui'
import AppLayoutMixin from "@/app-layout-mixin";


createApp({
    components: { NResult, NButton },
    mixins: [ AppLayoutMixin ],
    methods: {
        gotoHomepage() {
            location.href = '/';
        }
    }
}).mount('#app')
